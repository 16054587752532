<template>
  <div>
    <div style="padding-top: 10px;">
      <a href="javascript: void(0);" @click="mostrarTerminos()" class="flat-link">Términos y condiciones</a> |
      <a href="javascript: void(0);" @click="mostrarAviso()" class="flat-link">Aviso de privacidad</a>
    </div>
    <div :style="{
      'padding-top': '10px;',
      'color': modoOscuro ? '#FFF' : ''
      }"
    >&copy;{{ anio }} Hiperlector</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { currentYear } from "../helpers/date.helper";

export default {
  name: "mainFooter",
  computed: {
    ...mapGetters(["modoOscuro"]),
  },
  data: () => ({
    anio: null,
  }),
  created() {
    this.setAnio();
  },
  methods: {
    setAnio() {
      this.anio = currentYear();
    },
    mostrarTerminos(){
        this.$router.push('/terminos-y-condiciones');
    },
    mostrarAviso(){
        this.$router.push('/aviso-privacidad');
    }
  }
};
</script>
