<template>
  <div class="text-center ma-2">
    <v-snackbar v-model="mostrar" :color="color" rounded>
      {{ mensaje }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "appError",
  computed: {
    ...mapGetters(["appError"]),
    color(){
      let color = ''
      if(this.tipo == 'error') color = 'red darken-2'
      if(this.tipo == 'warning') color = 'orange darken-2'
      return color;
    }
  },
  watch: {
    appError(value) {
      if (value) {
        this.mensaje = value.mensaje;
        this.tipo = value.tipo;
        this.mostrar = true;
      }
      this.$store.dispatch("setAppError", null);
    },
  },
  data() {
    return {
      mostrar: false,
      tipo: 'error',
      mensaje: "Mensaje provicional",
    };
  },
};
</script>
